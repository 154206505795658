import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Strong, Span, Link, List, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";

import { useLoginState } from './userStateContext';

import localFavicon from '../resources/Icons/logo_32x32.ico';
import SOMALogo from '../resources/Logos/Logo.jpeg';

export default (() => {

	const { loginState, updateLoginState } = useLoginState(false);

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"ethical-guidelines"} />
		<Helmet>
			<title>
				Ethicl Guidelines
			</title>
			<meta name={"description"} content={"Web site created for NJSOMA"} />
			<link rel={"shortcut icon"} href={localFavicon} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Override slot="SectionContent" />
			<Box
				min-width="100px"
				min-height="100px"
				order="0"
				align-self="stretch"
				display="flex"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						<Image
							src={SOMALogo}
							display="block"
							max-height="150px"
							max-width="150px"
							srcSet=""
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px" align-self="center">
					<Text margin="0px 0 24px 0" text-align="center" font="--headline2" md-font="--headline3">
						National Journal of Society of Medical Anatomists
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Input
						display="block"
						placeholder-color="LightGray"
						background="white"
						id="loginUser"
						className="LoginUser"
						defaultValue="User ID :"
						disabled
						opacity={0}/*{loginState ? 1 : 0}*/
					/>
					<Button opacity={0}/*{loginState ? 0 : 1}*/ id="loginRegBtn" className="LoginRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Login
					</Button>
					<Button opacity={0}/*{loginState ? 1 : 0}*/ id="logoutRegBtn" className="LogoutRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Logout
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Box min-width="100px" min-height="100px" />
			<Box>
				<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
					<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
					<Override
						slot="menu"
						md-top={0}
						md-width="100%"
						justify-content="center"
						lg-transition="transform 400ms ease 0s"
						md-position="fixed"
						display="flex"
						md-left={0}
						lg-transform="translateY(0px) translateX(0px)"
						md-height="100%"
						padding="0px 0 0px 0"
						align-items="center"
					>
						<Override
							slot="item"
							md-padding="16px 40px 16px 40px"
							display="inline-block"
							text-transform="uppercase"
							text-align="center"
							padding="8px 20px 8px 20px"
						/>
						<Override slot="item-404" lg-display="none" display="none" />
						<Override slot="item-index" lg-display="none" display="none" />
						<Override
							slot="link"
							md-hover-opacity="1"
							md-active-opacity="1"
							md-color="--dark"
							font="--base"
							text-transform="initial"
							md-font="16px/24px sans-serif"
							text-decoration-line="initial"
							color="--dark"
							transition="opacity .15s ease 0s"
							letter-spacing="0.5px"
							md-opacity=".5"
							md-transition="opacity .15s ease 0s"
							opacity=".5"
							hover-opacity="1"
						/>
						<Override
							slot="link-active"
							md-opacity="1"
							md-cursor="default"
							opacity="1"
							color="--primary"
							cursor="default"
						/>
					</Override>
					<Override slot="icon,icon-close" category="md" icon={MdMenu} />
					<Override
						slot="icon"
						category="md"
						icon={MdMenu}
						size="36px"
						md-right="0px"
						md-position="relative"
					/>
					<Override
						slot="menu-open"
						md-justify-content="center"
						md-top={0}
						md-bottom={0}
						md-display="flex"
						md-flex-direction="column"
						md-align-items="center"
					/>
				</Components.BurgerMenu>
			</Box>
		</Section>
		<Section>
			<Box min-width="100px" min-height="100px">
				<Link href="./#editorial-policies" color="#000000">
					Editorial Policies | 
				</Link>
				<Link href="./#plagiarism" color="#000000">
					Plagiarism | 
				</Link>
				<Link href="./#peer-review" color="#000000">
					Peer Review | 
				</Link>
				<Link href="./#handling-errata-policy" color="#000000">
					Policy for handling errata | 
				</Link>
				<Link href="./#errata" color="#000000">
					Errata | 
				</Link>
				<Link href="./#retractions" color="#000000">
					Retractions | 
				</Link>
				<Link href="./#pfirs" color="#000000">
					Process for issuing a retraction statement | 
				</Link>
				<Link href="./#withdrawals" color="#000000">
					Withdrawals | 
				</Link>
				<Link href="./#eoc" color="#000000">
					Expressions of Concern | 
				</Link>
				<Link href="./#complaints-procedure" color="#000000">
					Complaint’s procedure | 
				</Link>
				<Link href="./#coi" color="#000000">
					Conflicts of Interest | 
				</Link>
				<Link href="./#jpis" color="#000000">
					Journal policy on In-House Submissions | 
				</Link>
				<Link href="./#ptrppm" color="#000000">
					Permissions To Reproduce Previously Published Material | 
				</Link>
				<Link href="./#pcf" color="#000000">
					Patient Consent Forms | 
				</Link>
				<Link href="./#eca" color="#000000">
					Ethics committee approval | 
				</Link>
				<Link href="./#cope-links" color="#000000">
					Core Practice Guidelines |
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-6">
			<Override slot="SectionContent" padding="0px 0 0px 0px" lg-padding="0px 0 0px 0px" />
			<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans" max-width="840px">
				Ethical Guidelines :
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="editorial-policies"
				>
					<Strong>
						Editorial Policies
					</Strong>
					<br />
				</Span>
				{" \n\t\t"}NJSOMA rely on the integrity and honesty of their Editors to publish the best manuscript that conforms to the ethical standards and meets all the requirements as per the mission of the journal for publication. NJSOMA encourages its editors to follow the Committee on Publication Ethics (COPE){" "}
				<Link href="https://publicationethics.org/files/u2/Best_Practice.pdf">
					"Best Practice Guidelines for Journal Editors"
				</Link>
				.{" "}
				<br />
				<br />
				NJSOMA adhere to the professional and industry guidelines and best practices in scientific publications, including the Recommendations for the Conduct, Reporting, Editing, and Publication of Scholarly Work in Medical Journals (ICMJE) and Principles of Transparency and Best Practice in Scholarly Publishing (
				<Link href="https://oaspa.org/principles-of-transparency-and-best-practice-in-scholarly-publishing-4/">
					joint statement by COPE, DOAJ, WAME, and OASPA
				</Link>
				)
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="plagiarism"
				>
					<Strong>
						Plagiarism
					</Strong>
					<br />
				</Span>
				{" \n\t\t"}"To steal and pass off the ideas or words of another as one's own," "use another's production without crediting the source," 
				or "present as new and original an idea or product derived from an existing source." The NJSOMA also considers "self-plagiarism" as a 
				form of plagiarism. An example of self-plagiarism would be when an author borrows from his or her own previously published work without 
				the proper citation within the newly submitted manuscript. We encourage our editors and reviewers to use the plagiarism check. The 
				manuscript's found to have plagiarism is rejected.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="peer-review"
				>
					<Strong>
						Peer Review
					</Strong>
					<br />
				</Span>
				{" \n\t\t"}NJSOMA rely on the double-blind peer review process to assess the quality of the manuscript to be published. NJSOMA follows a double-blind review process, in which the author identities are concealed from the reviewers, and vice versa, throughout the review process. Committee on Publication Ethics (COPE) guidelines on the peer review process can be found{" "}
				<Link href="https://publicationethics.org/peerreview">
					here
				</Link>
				{" \n\t\t "}and Guidelines for the reviewers can be found{" "}
				<Link href="https://publicationethics.org/resources/guidelines/cope-ethical-guidelines-peer-reviewers">
					here
				</Link>
				.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="handling-errata-policy"
				>
					<Strong>
						Policy for handling errata, retractions, withdrawals, and expressions of concern
					</Strong>
					<br />
				</Span>
				NJSOMA abides by{" "}
				<Link href="https://publicationethics.org/retraction-guidelines">
					COPE Retraction Guidelines
				</Link>
				.{" "}
				<br />
				It is important to establish clear ethical guidelines to ensure that all content is produced and distributed in a responsible and ethical manner.
				<br />
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="decimal"
					as="ul"
					xl-font="300 25px/1.5 --fontFamily-sans"
				>
					<Text margin="0px 0px 0px 0px">
						<Strong>
							Accuracy and fact checking
						</Strong>
						{" "}: We strive to publish accurate and truthful content, and we take all necessary steps to fact-check information before publication. We correct any errors promptly and transparently.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong>
							Editorial independence
						</Strong>
						{" "}: We maintain editorial independence and do not allow advertisers, sponsors, or any external parties to influence our content in any way.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong>
							Transparency{" "}
						</Strong>
						: We disclose any conflicts of interest and relationships with external parties that may influence our content, and we provide clear attribution for sources and contributors.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong>
							Respect for diversity and inclusivity
						</Strong>
						{" "}: We respect and celebrate diversity in all its forms, and we strive to represent diverse perspectives and voices in our content.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong>
							Protection of personal information
						</Strong>
						{" "}: We respect individuals‟ right to privacy and we protect personal information in accordance with applicable laws and regulations.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong>
							Copyright and intellectual property
						</Strong>
						{" "}: We respect the intellectual property rights of others and do not infringe on any copyrights or trademarks in our content.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong>
							Non-discrimination
						</Strong>
						{" "}: We do not publish content that discriminates against any individual or group based on race, ethnicity, gender, sexual orientation, religion, or any other protected characteristic.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong>
							Responsibility for content
						</Strong>
						{" "}: We take responsibility for all content published under our name, and we promptly address any complaints or concerns related to our content.
					</Text>
				</List>
				<br />
				These guidelines are intended to provide a framework for ethical behaviour and decision-making. We expect all contributors, and partners to adhere to these guidelines and uphold the highest standards of ethical conduct.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="errata"
				>
					<Strong>
						Errata
					</Strong>
					<br />
				</Span>
				{" \n\t\t"}An erratum is a correction of a factual error that does not alter the overall conclusions of a published work. When an error is identified, we will promptly correct the error and publish an erratum that clearly identifies the correction and its implications, such changes are intimated to respective indexing agencies.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="retractions"
				>
					<Strong>
						Retractions
					</Strong>
					<br />
				</Span>
				{" \n\t\t"}A retraction is a notice that a published work is no longer valid or reliable. Retractions may be necessary when a work contains serious errors, plagiarism, or other forms of misconduct. The publisher and the editorial team of the journal will investigate any concerns raised about published content and, if necessary, issue a retraction that clearly explains the reasons for the retraction, such changes are intimated to respective indexing agencies.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="pfirs"
				>
					<Strong>
						Process for issuing a retraction statement
					</Strong>
					<br />
				</Span>
				Where the decision is taken to retract and the article to be retracted is the Version of Record (i.e. it has been published as Online First or within an issue of a journal), Scientific Scholar recommends issuing a retraction statement which should be published separately and should be linked to the article being retracted. A "retracted" watermark should also be added to the article; however, the article as first published should be retained online to maintain the scientific record. Issuing a retraction statement will mean the following:{" "}
				<br />
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="decimal"
					as="ul"
					xl-font="300 25px/1.5 --fontFamily-sans"
				>
					<Text margin="0px 0px 0px 0px">
						The retraction will appear on a numbered page in a prominent section of the journal.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						The retraction will be listed in the contents page and the title of the original article will be included in its heading.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						The text of the retraction should explain why the article is being retracted; and{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						The statement of retraction and the original article must be clearly linked in the electronic database so that the retraction will always be apparent to anyone who comes across the original article.
					</Text>
				</List>
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="withdrawals"
				>
					<Strong>
						Withdrawals
					</Strong>
					<br />
				</Span>
				A withdrawal is a notice that a published work is being removed from circulation because of ethical concerns or legal issues. Withdrawals may be necessary when a work contains fraudulent or unethical research or when legal issues arise. We will investigate any concerns raised about published content and, if necessary, issue a withdrawal that clearly explains the reasons for the withdrawal. Any manuscript before or post acceptance can be withdrawn with a clear reason and the editorial team of the respective journals take appropriate action. Scientific Scholar as a publisher do not interfere in any decision making during these process.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="eoc"
				>
					<Strong>
						Expressions of Concern
					</Strong>
					<br />
				</Span>
				An expression of concern is a notice that a published work is being reviewed for potential problems, but no decision has been made about whether to retract or correct the work. Expressions of concern may be necessary when there are concerns about the validity or reliability of a published work. The publisher and the editorial team will investigate any concerns raised about published content and, if necessary, issue an expression of concern that clearly explains the reasons for the concern and the steps being taken to address the issue. These policies are intended to provide a framework for addressing issues that may arise with published content. We will follow these policies with care and transparency, and communicate clearly with all stakeholders throughout the process.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="complaints-procedure"
				>
					<Strong>
						Complaint’s procedure
					</Strong>
					<br />
				</Span>
				To challenge to a retraction or a related issue, NJSOMA procedure is as follows:{" "}
				<br />
				<br />
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="decimal"
					as="ul"
					xl-font="300 25px/1.5 --fontFamily-sans"
				>
					<Text margin="0px 0px 0px 0px">
						The complaint may be submitted via the journal editor.
					</Text>
					<Text margin="0px 0px 0px 0px">
						The investigation involves reviewing all correspondence relating to the case in question and, if necessary, obtaining further written responses to queries from the parties involved.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						The purpose of the investigation is to establish that correct procedures have been followed, that decisions have been reached based on academic criteria, that personal prejudice or bias of some kind has not influenced the outcome, and that appropriate sanctions have been applied where relevant.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						Complainants may choose to take their complaint to the{" "}
						<Link href="https://publicationethics.org/">
							Committee on Publication Ethics{" "}
						</Link>
						(COPE).
					</Text>
				</List>
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="coi"
				>
					<Strong>
						Conflicts of Interest
					</Strong>
					<br />
				</Span>
				The NJSOMA requires the authors to sign a disclosure form at the time of manuscript submission. Author/authors are expected to disclose any conflicts or financial interests impacting the outcome of the study in which he/she or they are involved. If the manuscript is accepted, the Conflict of Interest information will be communicated in a published statement. COPE guidelines on conflict of interest can be found{" "}
				<Link href="https://publicationethics.org/competinginterests">
					here
				</Link>
				.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="jpis"
				>
					<Strong>
						Journal policy on In-House Submissions
					</Strong>
					<br />
				</Span>
				In-house submissions that contain the work of any editorial board member, are not allowed to be reviewed by that editorial board member and all decisions regarding this manuscript are made by an independent editor. In addition, these manuscripts are reviewed by the two external reviewers.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="ptrppm"
				>
					<Strong>
						Permissions To Reproduce Previously Published Material
					</Strong>
					<br />
				</Span>
				Permission is required to reproduce material (such as illustrations) from the copyright holder. Articles cannot be published without these permissions.
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="pcf"
				>
					<Strong>
						Patient Consent Forms
					</Strong>
					<br />
				</Span>
				Potential participants should make their own decision about whether they want to participate or continue participating in research. This should be done through a process of informed consent in which individuals (1) are accurately informed of the purpose, methods, risks, benefits, and alternatives to the research, (2) understand this information and how it relates to their own clinical situation or interests, and (3) make a voluntary decision about whether to participate. A statement to the effect that such consent had been obtained must be included in the 'Methods' section of your manuscript. If necessary the Editors may request a copy of consent forms
			</Text>
			<Text
				margin="0px 0px 25px 0px"
				font="normal 300 25px/1.5 --fontFamily-sans"
				color="#505257"
				max-width="90%"
				xl-text-align="justify"
				xl-padding="0px 5px 0px 5px"
			>
				<Span
					xl-text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="eca"
				>
					<Strong>
						Ethics committee approval
					</Strong>
					<br />
				</Span>
				All studies that involve the humans need to have approval for the study from the respective institutional review Board (IRB) for the human studies. These guidelines may vary from country to country and country specific guidelines need to be followed. The IRB number and protocol number should be stated in the manuscript.{" "}
				<br />
				<br />
				If World Medical Association (WMA) the Declaration of Helsinki ethical principles for medical research involving human subjects were followed, they should be stated in the method section of the manuscript. (See{" "}
				<Link href="https://www.wma.net/policies-post/wma-declaration-of-helsinki-ethical-principles-for-medical-research-involving-human-subjects/">
					here{" "}
				</Link>
				for more information.){" "}
				<br />
				<br />
				If the study involves a Drug under investigation such as in clinical trial, its approval by the FDA or equivalent authority be obtained and stated in the manuscript. (See{" "}
				<Link href="https://www.fda.gov/science-research/clinical-trials-and-human-subject-protection/regulations-good-clinical-practice-and-clinical-trials">
					here{" "}
				</Link>
				for more information.) Any study involving the animals for research should have approval of the protocol from the Institutional committee on the animal resources.
			</Text>
		</Section>
		<Section>
			<Box min-width="100px" min-height="100px" id="cope-links">
				<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
					We follow the latest Core Practice Guidelines for Editors and Journal publishers as outlined by the{" "}
					<Link href="https://publicationethics.org/core-practices">
						COPE
					</Link>
					.
					<br />
					<Link
						href="https://publicationethics.org/misconduct"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Allegations of misconduct
					</Link>
					<br />
					<Link
						href="https://publicationethics.org/authorship"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Authorship and contributorship
					</Link>
					<br />
					<Link
						href="https://publicationethics.org/appeals"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Complaints and appeals
					</Link>
					<br />
					<Link
						href="https://publicationethics.org/competinginterests"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Conflicts of interest / Competing interests
					</Link>
					<br />
					<Link
						href="https://publicationethics.org/data"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Data and reproducibility
					</Link>
					<br />
					<Link
						href="https://publicationethics.org/oversight"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Ethical oversight
					</Link>
					<br />
					<Link
						href="https://publicationethics.org/intellectualproperty"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Intellectual property
					</Link>
					<br />
					<Link
						href="https://publicationethics.org/management"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Journal management
					</Link>
					<br />
					<Link
						href="https://publicationethics.org/peerreview"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Peer review processes
					</Link>
					<br />
					<Link
						href="https://publicationethics.org/postpublication"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Post-publication discussions and corrections
					</Link>
					.
				</Text>
			</Box>
		</Section>
		<Section
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="center"
			margin="0 0 0 0"
			padding="16px 0 16px 0"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
				grid-gap="32px"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="28px"
						height="28px"
						src={SOMALogo}
						srcSet=""
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						SOMA
					</Link>
				</Box>
				<SocialMedia facebook="https://www.facebook.com/NJSOMA/" twitter="https://twitter.com/njsoma" youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw" justify-content="flex-end">
					<Override slot="link" background="none" border-radius="50%" />
					<Override slot="icon" color="--dark" />
				</SocialMedia>
			</Box>
		</Section>
	</Theme>;
});